@tailwind base;
@tailwind components;
@tailwind utilities;


body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.iconFilter{
  filter: invert(56%) sepia(86%) saturate(4217%) hue-rotate(165deg) brightness(91%) contrast(101%);

}

.iconFilter2{
  filter: invert(88%) sepia(5%) saturate(2422%) hue-rotate(314deg) brightness(114%) contrast(111%);

}



